<template>
  <b-card
    no-body
    :style="
      isDark
        ? 'background-color: #343d55; border-color: #343d55'
        : 'background-color: #f3f2f7; border-color: #e6e9eb'
    "
  >
    <template #header>
      <b-col>
        <b-row class="justify-content-between">
          <b-col class="p-0 m-0">
            <p class="p-0 m-0">Listed Cases</p>
          </b-col>
          <b-col>
            <b-row class="justify-content-end">
              <hearing-filter class="pr-50"></hearing-filter>
              <hearing-download-button></hearing-download-button>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </template>
    <!--/ title and dropdown -->
    <b-overlay
      :show="
        $store.state.hearingStore.hearingLoading &&
        !$store.state.hearingStore.allHearings.length
      "
      rounded="sm"
      variant="transparent"
      opacity="0.5"
      blur="2px"
    >
      <div style="max-height: 530px; min-height: 529px; overflow-y: scroll">
        <div v-if="$store.state.hearingStore.allHearings.length">
          <b-card
            no-body
            v-for="(item, index) in $store.state.hearingStore.allHearings"
            v-bind:key="item.date"
            class="m-0 p-0 rounded-0"
          >
            <b-list-group flush>
              <b-list-group-item class="flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                  <h4 class="mb-1 text-black">Hearing Date: {{ item.date }}</h4>
                  <small class="text-secondary"
                    >{{ item.data.length }} Cases</small
                  >
                </div>
                <div
                  v-for="(data, itemIndex) in item.data"
                  v-bind:key="data._id"
                >
                  <b-list-group-item>
                    <router-link
                      class="mb-1 text-primary"
                      :to="{
                        name: data.hc_name.includes('oc-')
                          ? 'dashboard-other-matter-preview'
                          : 'dashboard-matter-preview',
                        params: { id: data._id }
                      }"
                      target="_blank"
                    >
                      <h6 class="mb-50 text-primary">
                        {{ data.title }}
                      </h6>
                    </router-link>
                    <small
                      class="pb-50"
                      v-if="Object.keys(data).includes('file_no')"
                      >File No.: {{ data.file_no }}</small
                    >
                    <b-row class="row">
                      <b-col class="col-12">
                        <b-row class="justify-content-between">
                          <b-col
                            class="mr-auto cursor-pointer"
                            @click="
                              $router.push({
                                name: data.hc_name.includes('oc-')
                                  ? 'dashboard-other-matter-preview'
                                  : 'dashboard-matter-preview',
                                params: { id: data._id }
                              })
                            "
                          >
                            <h5 class="font-weight-bold">
                              {{ getUniqueId(data) }}
                            </h5>
                            <small
                              v-if="Object.keys(data).includes('cause_name')"
                            >
                              <small v-if="data.cause_name"
                                >[ {{ data.cause_name }} ]</small
                              >
                            </small>
                          </b-col>
                          <b-col class="text-end">
                            <b-row
                              v-if="
                                Object.keys(data).includes('last_order_url')
                              "
                              class="justify-content-end"
                            >
                              <b-badge
                                variant="light"
                                pill
                                class="badge-round cursor-pointer"
                                v-if="data.last_order_url"
                                @click="
                                  exportJudgment({
                                    url: data.last_order_url,
                                    date: data.last_order.datetime,
                                    matter_id: data.unique_id
                                  })
                                "
                              >
                                <feather-icon
                                  :id="`order-${data._id}-preview-icon`"
                                  icon="Link2Icon"
                                  size="16"
                                  class="mr-25 text-primary"
                                />
                                <span class="text-primary">Last Order</span>
                              </b-badge>
                            </b-row>
                          </b-col>
                        </b-row>
                        <div
                          class="cursor-pointer"
                          @click="
                            $router.push({
                              name: data.hc_name.includes('oc-')
                                ? 'dashboard-other-matter-preview'
                                : 'dashboard-matter-preview',
                              params: { id: data._id }
                            })
                          "
                        >
                          <div v-if="Object.keys(data).includes('ctitle')">
                            <small class="text-secondary font-italic">{{
                              data.ctitle
                            }}</small>
                          </div>

                          <b-row class="justify-content-between px-1">
                            <div
                              v-if="
                                Object.keys(data).includes('causelist_type')
                              "
                            >
                              <small
                                class="text-secondary"
                                v-if="data.causelist_type"
                                >({{ data.causelist_type }})</small
                              >
                            </div>
                            <div v-if="Object.keys(data).includes('list_type')">
                              <small
                                class="text-secondary"
                                v-if="data.list_type"
                                >(List Type: {{ data.list_type }})</small
                              >
                            </div>
                          </b-row>
                          <div v-if="Object.keys(data).includes('stats')">
                            <div v-if="data.stats">
                              <div v-if="getStats(data.stats) != ''">
                                <small class="text-secondary"
                                  >{{ getStats(data.stats) }}
                                </small>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="
                              Object.keys(data).includes('serial_number') ||
                              Object.keys(data).includes('court_number')
                            "
                          >
                            <small
                              class="text-primary font-weight-bolder text-uppercase"
                              v-if="
                                data.court_number !== null ||
                                data.serial_number > 0
                              "
                            >
                              Listed:
                            </small>
                            <small
                              v-if="Object.keys(data).includes('serial_number')"
                            >
                              <small
                                class="text-primary font-weight-bolder text-uppercase"
                                v-if="data.serial_number > 0"
                              >
                                [ Sr No. {{ data.serial_number }} ]
                              </small>
                            </small>
                            <small
                              v-if="Object.keys(data).includes('court_number')"
                            >
                              <small v-if="checkIfInteger(data.court_number)">
                                <small
                                  class="text-primary font-weight-bolder text-uppercase"
                                  v-if="data.court_number !== null"
                                >
                                  - [ Court No.
                                  {{
                                    data.court_number <= 0
                                      ? getStringCourtNumber(data.court_number)
                                      : data.court_number
                                  }}
                                  ]
                                </small>
                              </small>
                              <small v-else>
                                <small
                                  class="text-primary font-weight-bolder text-uppercase"
                                  v-if="data.court_number !== null"
                                >
                                  - [ Court No.
                                  {{ data.court_number }}
                                  ]
                                </small>
                              </small>
                            </small>
                            <small
                              v-if="
                                Object.keys(data).includes('transferred_court')
                              "
                            >
                              <small
                                class="text-success font-weight-bolder"
                                v-if="
                                  data.transferred_court != null &&
                                  data.transferred_court != -1
                                "
                              >
                                - [ TF to CT
                                {{
                                  data.transferred_court <= 0
                                    ? getStringCourtNumber(
                                        data.transferred_court
                                      )
                                    : data.transferred_court
                                }}
                                ]
                              </small>
                            </small>
                          </div>
                          <div v-if="Object.keys(data).includes('judges')">
                            <div v-if="data.judges">
                              <small
                                v-if="data.judges.length"
                                class="text-black font-weight-bold"
                              >
                                {{
                                  data.hc_name.includes('hc') ||
                                  data.hc_name.includes('sci')
                                    ? data.judges.length > 1
                                      ? 'JUSTICES'
                                      : 'JUSTICE'
                                    : ''
                                }}
                                {{ data.judges.join(', ') }}
                              </small>
                            </div>
                          </div>
                          <div
                            v-if="
                              data.hc_name.includes('hc') ||
                              data.hc_name.includes('sci')
                            "
                          >
                            <small
                              v-if="
                                Object.keys(data).includes('serial_number') &&
                                Object.keys(data).includes('court_number')
                              "
                            >
                              <small
                                v-if="
                                  data.court_number !== null &&
                                  data.serial_number > 0
                                "
                              >
                                <hearing-display-court
                                  :date="item.date"
                                  :court_id="data.court_id"
                                  :court_no="
                                    Object.keys(data).includes(
                                      'transferred_court'
                                    ) &&
                                    data.transferred_court != null &&
                                    data.transferred_court != -1
                                      ? data.transferred_court
                                      : data.court_number
                                  "
                                  :serial_no="data.serial_number"
                                />
                              </small>
                            </small>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </div>
        <p
          class="text-center mt-5"
          v-if="
            !$store.state.hearingStore.allHearings.length &&
            !$store.state.hearingStore.hearingLoading
          "
        >
          Record Not Found!
        </p>
      </div>
    </b-overlay>
    <b-card-footer class="m-0 p-0">
      <b-col>
        <b-row class="justify-content-center p-1">
          <b-button
            class="btn"
            variant="outline-info"
            :disabled="!$store.state.hearingStore.allHearings.length"
            @click="
              $router.push({
                name: 'cause-list'
              })
            "
            >View More</b-button
          >
        </b-row>
      </b-col>
    </b-card-footer>
  </b-card>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import HearingDisplayCourt from '../hearings/components/HearingDisplayCourt.vue'
import HearingDownloadButton from '../causelist/HearingDownloadButton.vue'
import moment from 'moment'
import HearingFilter from '../causelist/HearingFilter.vue'

import {
  BCard,
  BCardHeader,
  BCardFooter,
  BCardTitle,
  BButton,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BListGroup,
  BListGroupItem,
  BOverlay,
  BBadge,
  VBTooltip
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardFooter,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BButton,
    BCardBody,
    BRow,
    BBadge,
    BCol,
    BListGroup,
    BListGroupItem,
    BOverlay,
    HearingDisplayCourt,
    HearingFilter,
    HearingDownloadButton
  },
  directives: { 'b-tooltip': VBTooltip },
  beforeCreate() {
    this.$store.dispatch('hearingStore/fetchHearings')
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      interval: null
    }
  },
  beforeMount() {
    this.startInterval()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    checkIfInteger(court_number) {
      // Parse the input as a number and check if it's an integer
      const parsedValue = parseInt(court_number, 10)
      return Number.isInteger(parsedValue)
    },
    startInterval() {
      const beforeTime = moment('05:00 PM', 'LT').format('HH')
      const afterTime = moment('09:00 AM', 'LT').format('HH')
      const now = moment().format('HH')
      const day = moment().format('dddd')
      if (day !== 'Sunday') {
        if (now > afterTime && now < beforeTime) {
          this.$store.dispatch('displayboardStore/fetchUserDisplayBoard')
          clearInterval(this.interval)
          this.interval = setInterval(
            () =>
              this.$store.dispatch('displayboardStore/fetchUserDisplayBoard'),
            30000
          )
        }
      }
    },
    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    },

    exportJudgment({ url, date, matter_id }) {
      let dd = date.toString().split('T')[0]
      let uu = matter_id.includes('-')
        ? matter_id.split('-').slice(2).join('/')
        : matter_id
      let href = url.replace(
        'http://s3.mymunshi.live',
        'https://app.mymunshi.in/exports'
      )
      this.downloadAs(href, `${uu}-order-${dd}.pdf`)
    },

    getStats(stats) {
      let sList = []
      let sResult = ''

      if (stats != null) {
        if (
          stats.hasOwnProperty('total_fresh_cases') &&
          stats['total_fresh_cases'] !== null &&
          typeof stats['total_fresh_cases'] === 'number' &&
          stats['total_fresh_cases'] > 0
        ) {
          sList.push('F-' + stats['total_fresh_cases'])
        }

        if (
          stats.hasOwnProperty('total_add_cases') &&
          stats['total_add_cases'] !== null &&
          typeof stats['total_add_cases'] === 'number' &&
          stats['total_add_cases'] > 0
        ) {
          sList.push('Add-' + stats['total_add_cases'])
        }

        if (
          stats.hasOwnProperty('total_ia_cases') &&
          stats['total_ia_cases'] !== null &&
          typeof stats['total_ia_cases'] === 'number' &&
          stats['total_ia_cases'] > 0
        ) {
          sList.push('App-' + stats['total_ia_cases'])
        }

        if (
          stats.hasOwnProperty('ordinary') &&
          stats['ordinary'] !== null &&
          typeof stats['ordinary'] === 'number' &&
          stats['ordinary'] > 0
        ) {
          sList.push('Ord-' + stats['ordinary'])
        }

        if (
          stats.hasOwnProperty('urgent') &&
          stats['urgent'] !== null &&
          typeof stats['urgent'] === 'number' &&
          stats['urgent'] > 0
        ) {
          sList.push('Urg-' + stats['urgent'])
        }
      }

      if (sList.length > 0) {
        sResult = '( '
        sResult += sList.join(' ')
        sResult += ' )'
      }

      return sResult
    }
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  }
}
</script>
