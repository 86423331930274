var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{style:(_vm.isDark
      ? 'background-color: #343d55; border-color: #343d55'
      : 'background-color: #f3f2f7; border-color: #e6e9eb'),attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"border-bottom"},[_c('b-card-title',[_vm._v("Legal Updates")])],1),_c('b-overlay',{attrs:{"show":_vm.$store.state.lawtrendStore.newsLoading,"rounded":"sm","variant":"transparent","opacity":"0.5","blur":"2px"}},[_c('div',{staticStyle:{"max-height":"530px","min-height":"529px","overflow-y":"scroll"}},[(_vm.$store.state.lawtrendStore.newsList.length)?_c('div',_vm._l((_vm.$store.state.lawtrendStore.newsList),function(item,index){return _c('b-card',{key:item.id,staticClass:"m-0 p-0 rounded-0"},[_c('div',{staticClass:"media-list media-bordered cursor-pointer",on:{"click":function($event){return _vm.$router.push({
                name: 'news-preview',
                params: { id: item.id }
              })}}},[_c('b-media',{staticClass:"p-0",scopedSlots:_vm._u([{key:"aside",fn:function(){return [(item.better_featured_image !== null)?_c('b-img',{staticClass:"my-50",attrs:{"thumbnail":"","fluid":"","src":item.better_featured_image.media_details.sizes.medium
                      .source_url,"blank-color":"#ccc","width":"120","alt":"placeholder"}}):_c('b-img',{staticClass:"my-50",attrs:{"thumbnail":"","fluid":"","src":"\n                    https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1rta2lwSUnj0OiSS7Ist-MFgmmfoYQ1i71Lq0_J1HfFdSTLNPyBgh_EeQYPteIVI3qbE&usqp=CAU\n                  ","blank-color":"#ccc","width":"120","alt":"placeholder"}})]},proxy:true}],null,true)},[_c('h5',{directives:[{name:"snip",rawName:"v-snip",value:({ lines: 2 }),expression:"{ lines: 2 }"}],staticClass:"media-heading text-primary",domProps:{"innerHTML":_vm._s(item.title.rendered)}}),_c('p',{directives:[{name:"snip",rawName:"v-snip",value:({ lines: 1 }),expression:"{ lines: 1 }"}],staticClass:"text-dark m-0",domProps:{"innerHTML":_vm._s(item.excerpt.rendered)}}),_c('small',{staticClass:"p-0 m-0 text-info"},[_vm._v(_vm._s(_vm.getSubDate(item.date)))])])],1)])}),1):_vm._e(),(
          !_vm.$store.state.lawtrendStore.newsList.length &&
          !_vm.$store.state.lawtrendStore.newsLoading
        )?_c('p',{staticClass:"text-center mt-5"},[_vm._v(" Record Not Found! ")]):_vm._e()])]),_c('b-card-footer',{staticClass:"m-0 p-0"},[_c('b-col',[_c('b-row',{staticClass:"justify-content-center p-1"},[_c('b-button',{staticClass:"btn",attrs:{"variant":"outline-info","disabled":!_vm.$store.state.lawtrendStore.newsList.length},on:{"click":function($event){return _vm.$router.push({
              name: 'lawtrend-news'
            })}}},[_vm._v("View More")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }