<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Yearly Statistics</b-card-title>
    </b-card-header>

    <!-- chart -->
    <b-card-body v-if="latestBarChart.data.labels.length">
      <chartjs-component-bar-chart
        :height="245"
        :data="latestBarChart.data"
        :options="latestBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from './components/ChartjsComponentBarChart.vue'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    flatPickr,
    ChartjsComponentBarChart
  },
  data() {
    return {
      latestBarChart: {
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: '#28dac6',
              borderColor: 'transparent'
            }
          ]
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom'
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)'
                },
                scaleLabel: {
                  display: false
                },
                ticks: {
                  fontColor: '#6e6b7b'
                }
              }
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)'
                },
                ticks: {
                  stepSize: 50,
                  fontColor: '#6e6b7b'
                }
              }
            ]
          }
        }
      },
      rangePicker: ['2019-05-01', '2019-05-10']
    }
  },
  created() {
    this.$store
      .dispatch('matterStore/fetchTrackerCountYearly')
      .then((response) => {
        if (this.$store.state.matterStore.trackerCountYearly !== null) {
          var byYear = this.$store.state.matterStore.trackerCountYearly.slice(0)
          byYear.sort(function (a, b) {
            return a.case_year - b.case_year
          })
          let year = []
          let value = []
          for (let i = 0; i < byYear.length; i++) {
            year.push(byYear[i].case_year)
            value.push(byYear[i].count)
          }
          this.latestBarChart.data.labels = year
          this.latestBarChart.data.datasets[0].data = value
        }
      })
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
