<template>
  <b-card
    no-body
    :style="
      isDark
        ? 'background-color: #343d55; border-color: #343d55'
        : 'background-color: #f3f2f7; border-color: #e6e9eb'
    "
  >
    <!-- title and dropdown -->
    <b-card-header class="border-bottom">
      <b-card-title>Legal Updates</b-card-title>
    </b-card-header>
    <!--/ title and dropdown -->
    <b-overlay
      :show="$store.state.lawtrendStore.newsLoading"
      rounded="sm"
      variant="transparent"
      opacity="0.5"
      blur="2px"
    >
      <div style="max-height: 530px; min-height: 529px; overflow-y: scroll">
        <div v-if="$store.state.lawtrendStore.newsList.length">
          <b-card
            v-for="(item, index) in $store.state.lawtrendStore.newsList"
            v-bind:key="item.id"
            class="m-0 p-0 rounded-0"
          >
            <div
              class="media-list media-bordered cursor-pointer"
              @click="
                $router.push({
                  name: 'news-preview',
                  params: { id: item.id }
                })
              "
            >
              <b-media class="p-0">
                <template #aside>
                  <b-img
                    thumbnail
                    fluid
                    v-if="item.better_featured_image !== null"
                    :src="
                      item.better_featured_image.media_details.sizes.medium
                        .source_url
                    "
                    blank-color="#ccc"
                    width="120"
                    alt="placeholder"
                    class="my-50"
                  />
                  <b-img
                    thumbnail
                    fluid
                    v-else
                    src="
                      https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1rta2lwSUnj0OiSS7Ist-MFgmmfoYQ1i71Lq0_J1HfFdSTLNPyBgh_EeQYPteIVI3qbE&usqp=CAU
                    "
                    blank-color="#ccc"
                    width="120"
                    alt="placeholder"
                    class="my-50"
                  />
                </template>
                <h5
                  class="media-heading text-primary"
                  v-snip="{ lines: 2 }"
                  v-html="item.title.rendered"
                />
                <p
                  class="text-dark m-0"
                  v-snip="{ lines: 1 }"
                  v-html="item.excerpt.rendered"
                />
                <small class="p-0 m-0 text-info">{{
                  getSubDate(item.date)
                }}</small>
              </b-media>
            </div>
          </b-card>
        </div>
        <p
          class="text-center mt-5"
          v-if="
            !$store.state.lawtrendStore.newsList.length &&
            !$store.state.lawtrendStore.newsLoading
          "
        >
          Record Not Found!
        </p>
      </div>
    </b-overlay>
    <b-card-footer class="m-0 p-0">
      <b-col>
        <b-row class="justify-content-center p-1">
          <b-button
            class="btn"
            variant="outline-info"
            :disabled="!$store.state.lawtrendStore.newsList.length"
            @click="
              $router.push({
                name: 'lawtrend-news'
              })
            "
            >View More</b-button
          >
        </b-row>
      </b-col>
    </b-card-footer>
  </b-card>
</template>

<script>
import { computed } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import moment from 'moment'

import {
  BCard,
  BCardHeader,
  BCardFooter,
  BCardTitle,
  BButton,
  BMedia,
  BImg,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BListGroup,
  BListGroupItem,
  BOverlay,
  BBadge,
  VBTooltip
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardFooter,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BButton,
    BCardBody,
    BMedia,
    BImg,
    BRow,
    BBadge,
    BCol,
    BListGroup,
    BListGroupItem,
    BOverlay
  },
  directives: { 'b-tooltip': VBTooltip },
  beforeCreate() {
    this.$store.dispatch('lawtrendStore/fetchNewsList', {
      queryParams: {
        page: 1,
        per_page: 15,
        categories_exclude: 694
      }
    })
  },
  methods: {
    getSubDate(date) {
      return moment(date).utc().fromNow()
    }
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  }
}
</script>