<template>
  <section id="dashboard-analytics">
    <b-row>
      <b-col lg="6">
        <analytics-hearings :data="data.supportTracker"></analytics-hearings>
      </b-col>
      <b-col lg="6">
        <analytics-news></analytics-news>
      </b-col>
      <b-col lg="6">
        <analytics-count></analytics-count>
      </b-col>
      <b-col lg="6">
        <analytics-count-yearly></analytics-count-yearly>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col>
        <analytics-timeline :data="data.timeline" />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'

import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
// import AnalyticsTimeline from './AnalyticsTimeline.vue'
import AnalyticsCount from './AnalyticsCount.vue'
import AnalyticsHearings from './AnalyticsHearings.vue'
import AnalyticsCountYearly from './AnalyticsCountYearly.vue'
import AnalyticsNews from '@/views/news/AnalyticsNews.vue'
import { useRouter } from 'vue-router'
import moment from 'moment'
import { getCurrentInstance } from 'vue'

export default {
  components: {
    BRow,
    BCol,
    AnalyticsSupportTracker,
    // AnalyticsTimeline,
    AnalyticsCount,
    AnalyticsHearings,
    AnalyticsCountYearly,
    AnalyticsNews
  },
  data() {
    return {
      data: {}
    }
  },
  created() {
    this.$store.dispatch('courtStore/fetchUserCourts')
    this.$store.dispatch('settingStore/getUser')
    // data
    this.$http.get('/analytics/data').then((response) => {
      this.data = response.data
    })
  },
  mounted() {
    this.checkPlanAlert();
  },
  methods: {
    kFormatter,
    showAlert(daysUntilExpiration) {
      // Show alert using a UI library like Element Plus or BootstrapVue
      // For example, using Element Plus
      this.$swal({
        title: 'Plan Alert',
        icon: 'warning',
        text: 'Your plan is about to expire in '+ daysUntilExpiration+ ' days, please upgrade for uninterrupted service.',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Upgrade',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-info ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$router.push({ name: 'account-setting' });
        }
      })
    },

    checkPlanAlert() {
      const user = JSON.parse(localStorage.getItem('userData'))
      console.log('User: ', user)
      if (user) {
        const now = moment().format('YYYY-MM-DD')
        let timestamps = JSON.parse(localStorage.getItem('plan-alert')) || []

        timestamps = timestamps.filter((timestamp) => {
          const date = moment(timestamp).format('YYYY-MM-DD')
          return date === now
        })

        const expiredDate = moment(user.expires_at)
        const daysUntilExpiration = expiredDate.diff(moment(), 'days')

        if (daysUntilExpiration <= 7) {
          if (
            timestamps.length === 0 ||
            (timestamps.length > 0 &&
              moment().diff(
                moment(timestamps[timestamps.length - 1]),
                'hours'
              ) >= 4)
          ) {
            timestamps.push(moment().toISOString())
            localStorage.setItem('plan-alert', JSON.stringify(timestamps))

            setTimeout(() => {
              this.showAlert(daysUntilExpiration)
            }, 3000) // 5 seconds delay
          }
        }
      }
    }
  }
}
</script>
